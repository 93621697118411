/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { API } from '../../../../../../../api';
// import { Close } from '@mui/icons-material';
import TaskPreviewLeftBar from './TaskPreviewLeftBar';
import { ITaskData, IAdminTask, adminTaskActions } from '../../../../../../../redux/slices/tasksSlice';
import {
  TaskPreviewHeaderLoader,
  TaskPreviewLeftBarLoader,
  TaskPreviewRightBarLoader
} from '../../../../../../../Loader/TaskPreviewLoader';
import TaskPreviewRightBar from './TaskPreviewRightBar';
import { getUserDetails } from '../../../../../../../utils/orgName';
import TooltipButton from '../../../../../../../components/TooltipButton';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { grpDetailsActions } from '../../../../../../../redux/slices/group-details/groupDetails';
import ProgramDeletDialog from '../../ProgramDeletDialog';
import { queryClient } from '../../../../../../../App';

interface TaskPreviewProps {
  onClose: () => void;
  selected: IAdminTask;
  selectedLevelId: string;
  goalName: string;
}

const TaskPreview: React.FC<TaskPreviewProps> = ({ onClose, selected, selectedLevelId, goalName }) => {
  const [levelId, setLevelId] = useState(selectedLevelId);
  const loading = useAppSelector((state) => state.allArticle.loading);
  const [selectedTask, setSelectedTask] = useState<IAdminTask>(selected);
  const [selectedTaskId, setSelectedTaskId] = useState<string>('');
  const goalTasks = useAppSelector((state) => state.tasks.data);
  const data = localStorage.getItem('task') ? JSON.parse(localStorage.getItem('task') || '') : goalTasks;
  const [tempTasks, setTempTasks] = useState<ITaskData | null>(null);

  useEffect(() => {
    setTempTasks(data);
  }, []);
  useEffect(() => {
    localStorage.setItem('task', JSON.stringify(tempTasks));
  }, [tempTasks]);
  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const { location } = getUserDetails();
  const grpData = useAppSelector((state) => state.groupDetails.data);

  const updateLatestTasks = (response: any) => {
    const levelIndex = tempTasks?.levels?.findIndex((level) => level._id === levelId);
    const taskIndex = tempTasks?.levels[levelIndex!]?.tasks?.findIndex((task) => task._id === selectedTaskId);
    setTempTasks(response?.data?.goals);
    const taskNew = response?.data?.goals?.levels[levelIndex ? levelIndex : 0]?.tasks[taskIndex ? taskIndex : 0];
    setSelectedTask(taskNew || {});
    setSelectedTaskId(taskNew?._id || '');
    setLevelId(response?.data?.goals?.levels[levelIndex ? levelIndex : 0]?._id || '');
    dispatch(adminTaskActions.updateTasks(response?.data?.goals));
  };
  const submitTasks = async (isPublish?: boolean) => {
    setSaveLoading(true);
    const payload =
      tempTasks?.levels.map((level, index) => {
        const tasks = level.tasks.map((each, taskIndex) => {
          return {
            taskName: each.taskName,
            taskDescription: each.taskDescription,
            menteeAnsType: each.menteeAnsType,
            menteeQues: each.menteeQues,
            tags: each.tags,
            taskPosition: taskIndex + 1,
            menteeAsmtQues: 'Have you completed this task?',
            menteeAsmtAnsType: 'Yes, No',
            menteeAsmtAnsOptions: ['Yes', 'No'],
            libraryArticles: each?.libraryArticles || []
          };
        });
        return {
          levelName: level.levelName,
          level: index + 1,
          CheckInWithMentor: 'no',
          tasks
        };
      }) || [];
    try {
      const response = await API.saveGroupTasks({
        data: { goalName, programType: data?.programType || '', levels: payload },
        grpId,
        location,
        orgId
      });
      if (response.status === 200 && response.statusText === 'OK') {
        if (isPublish) {
          await publishTasks();
        } else {
          const { programType, goalName, templateId, programKPIS } = response.data.goals;
          dispatch(grpDetailsActions.updateGroupGoalInfo({ programType, goalName, templateId, programKPIS }));

          toast.success('Your progress has been saved');
        }
        updateLatestTasks(response);
      }
    } catch (e) {
      toast.error('Task failed to save');
    } finally {
      setSaveLoading(false);
      setPublishLoading(false);
    }
  };
  const updateTasks = async () => {
    setSaveLoading(true);
    if (tempTasks?.levels) {
      const payload =
        tempTasks?.levels.map((level, index) => {
          const tasks = level.tasks.map((each, taskIndex) => {
            const taskObj: any = {
              taskName: each.taskName,
              taskDescription: each.taskDescription,
              menteeAnsType: each.menteeAnsType,
              menteeQues: each.menteeQues,
              tags: each.tags,
              taskPosition: taskIndex + 1,
              menteeAsmtQues: 'Have you completed this task?',
              menteeAsmtAnsType: 'Yes, No',
              menteeAsmtAnsOptions: ['Yes', 'No'],
              libraryArticles: each?.libraryArticles || []
            };
            if (!each.isNewTask) {
              taskObj.id = each._id;
            }
            return taskObj;
          });
          const levelObj: any = {
            levelName: level.levelName,
            level: index + 1,
            CheckInWithMentor: 'no',
            tasks
          };
          if (!level.isNewLevel) {
            levelObj.id = level._id;
          }
          return levelObj;
        }) || [];
      try {
        const response = await API.updateGroupTasks({
          data: { goalName: data?.goalName || '', programType: data?.programType || '', levels: payload },
          grpId,
          location,
          orgId,
          templateId: grpData?.goals.templateId || ''
        });
        if (response.status === 200) {
          updateLatestTasks(response);
        }
      } catch (e) {
        toast.error('Task failed to save');
      } finally {
        setSaveLoading(false);
      }
    }
  };
  const [disablePublish, setDisablePublish] = useState(false);

  useEffect(() => {
    if (tempTasks?.levels) {
      let error = false;
      if (tempTasks?.levels.length === 0) {
        setDisablePublish(true);
        return;
      }
      if (tempTasks?.levels.some((level) => level.tasks.length === 0)) {
        setDisablePublish(true);
        return;
      }
      tempTasks?.levels.map((level) => {
        const newTasks = level.tasks.map((task) => {
          const isValid = (task.menteeAnsType && task.menteeQues) || (!task.menteeAnsType && !task.menteeQues);
          if (!task.taskName || !isValid || !task.taskDescription?.trim() || task?.tags?.length === 0) {
            error = true;

            return { ...task, error: true };
          } else {
            return { ...task, error: false };
          }
        });
        return { ...level, tasks: newTasks };
      });
      if (error) {
        setDisablePublish(true);
      } else {
        setDisablePublish(false);
      }
    }
  }, [tempTasks, selectedTask]);

  const handleTempSave = () => {
    if (grpData?.goalPublished) {
      handleSave();
    } else {
      submitTasks(false);
    }
  };

  const handleSave = () => {
    if (tempTasks?.levels) {
      // let taskObj: IAdminTask = {};
      // let levelId = '';
      let error = false;
      if (tempTasks?.levels.length === 0) {
        toast.error('Please add at least one level');
        return;
      }
      if (tempTasks?.levels.some((level) => level.tasks.length === 0)) {
        toast.error('Please add at least one task');
        return;
      }
      let newTask = selectedTask;
      const newLevels = tempTasks?.levels.map((level) => {
        const newTasks = level.tasks.map((task) => {
          const isValid = (task.menteeAnsType && task.menteeQues) || (!task.menteeAnsType && !task.menteeQues);
          if (!task.taskName || !isValid || !task.taskDescription?.trim() || task?.tags?.length === 0) {
            error = true;
            if (task._id === selectedTask?._id) {
              newTask = { ...task, error: true };
            }
            // taskObj = task;
            // levelId = level._id;
            return { ...task, error: true };
          } else {
            return { ...task, error: false };
          }
        });
        return { ...level, tasks: newTasks };
      });
      if (error) {
        toast.error(`Please fill all the fields`);
        setTempTasks({ ...tempTasks, levels: newLevels });
        setLevelId(levelId);
        setSelectedTask(newTask);
        // setSelectedTask(taskObj);
        // setLevelId(levelId);
      } else {
        if (grpData?.goals.templateId && grpData?.goalPublished) {
          updateTasks();
        } else {
          submitTasks(false);
        }
      }
    }
  };

  const publishTasks = async () => {
    try {
      const response = await API.publishTask({ location, grpId, orgId });
      if (response.status === 200 && response.statusText === 'OK') {
        dispatch(grpDetailsActions.updatePublishKey(true));
        queryClient.fetchQuery({
          queryKey: ['groupDetails', grpId]
        });
        toast.success('Tasks published successfully');
      }
    } catch (e) {
      toast.error('Task failed to publish');
    } finally {
      setOpenDelete(false);
      setPublishLoading(false);
    }
  };
  const [publishLoading, setPublishLoading] = useState(false);

  const handlePublish = () => {
    setPublishLoading(true);
    if (grpData?.goals.templateId) {
      publishTasks();
    } else {
      submitTasks(true);
    }
  };
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  useEffect(() => {
    if (goalTasks && goalTasks.levels.length > 0) {
      if (selected?._id) {
        setSelectedTask(selected);
      } else {
        setSelectedTask(goalTasks.levels[0].tasks[0]);
      }
    }
  }, []);
  useEffect(() => {
    if (selectedTask?._id) {
      setSelectedTaskId(selectedTask?._id);
    }
  }, [selectedTask]);

  return (
    <Box height="100%" overflow="hidden">
      {/* Header */}
      {loading ? (
        <TaskPreviewHeaderLoader />
      ) : (
        <Box>
          {/* <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose} className="DialogClose" size="small" disabled={saveLoading}>
              <Close />
            </IconButton>
          </Box> */}

          <Box display="flex" justifyContent="space-between" alignItems="center" pr={2} pb={1}>
            <Box display={'flex'} gap={0.2} alignItems="center" onClick={onClose} className="cursor-pointer">
              <IconButton className="DialogClose" disabled={saveLoading}>
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
              <Typography variant="h4">Preview</Typography>
            </Box>

            <Box display={'flex'} gap={2}>
              <LoadingButton
                disabled={openDelete ? false : saveLoading}
                variant="contained"
                color="primary"
                className="w-150px h-50px"
                onClick={handleTempSave}
                loading={openDelete ? false : saveLoading}
              >
                Save
              </LoadingButton>
              <TooltipButton
                tooltipTitle="Only publish once you are ready to share with your members and you have completed all mandatory fields marked red"
                disabled={openDelete ? false : disablePublish || saveLoading || Boolean(grpData?.goalPublished)}
                onClick={handleClickOpenDelete}
                variant="contained"
                color="primary"
                className="w-150px h-50px"
                loading={false}
              >
                Publish
              </TooltipButton>
            </Box>
          </Box>
        </Box>
      )}

      {/* Main Body */}
      <Box height="calc(100% - 60px)">
        <Grid container gap="20px" height="100%" display="flex">
          {/* Left Side Bar */}
          <Grid item height="100%" width="350px">
            <Box height="100%" overflow="auto">
              {loading ? (
                <TaskPreviewLeftBarLoader />
              ) : (
                <TaskPreviewLeftBar
                  setSelectedTask={setSelectedTask}
                  selectedTaskId={selectedTaskId}
                  tempTasks={tempTasks}
                  setTempTasks={setTempTasks}
                  selectedLevelId={levelId}
                  setLevelId={setLevelId}
                />
              )}
            </Box>
          </Grid>

          {/* Right Side Bar */}
          <Grid item height="100%" width="calc(100% - 370px)">
            <Box height="100%" overflow="auto">
              {loading ? (
                <TaskPreviewRightBarLoader />
              ) : selectedTask?._id ? (
                <TaskPreviewRightBar
                  selectedTask={selectedTask}
                  setTempTasks={setTempTasks}
                  levelId={levelId}
                  tempTasks={tempTasks}
                />
              ) : (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  className="CardBox"
                  justifyContent="center"
                >
                  <Typography>Please create or select any task to view and edit</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ProgramDeletDialog
        openDelete={openDelete}
        deleteLoader={publishLoading}
        handleDelete={handlePublish}
        setDelete={setOpenDelete}
        text="Publishing will update all members plans now"
        btnText="Publish"
      />
    </Box>
  );
};

export default TaskPreview;
